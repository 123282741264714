/* @import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 18px;
  @apply text-chocolate;
  max-width: 100vw;
  overflow-x: hidden;
}

@media screen and (min-width: 640px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 19px;
  }
}

.rich-text-hero a {
  @apply text-primary-200 underline;
}

.rich-text-banner {
  @apply whitespace-pre-line;

  a {
    @apply underline;
  }
}

.title {
  @apply text-4xl leading-snug font-semibold;
}

@media (min-width: 768px) {
  .title {
    @apply text-5xl;
  }
}

.with-arrow:after {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%206a.5.5%200%200%200%200%201V6zM12.854.646a.5.5%200%200%200-.708.708l.708-.708zM18%206.5l.354.354a.5.5%200%200%200%200-.708L18%206.5zm-5.854%205.146a.5.5%200%200%200%20.708.708l-.708-.708zM1%207h16.5V6H1v1zm16.646-.854l-5.5%205.5.708.708%205.5-5.5-.708-.708zm-5.5-4.792l2.75%202.75.708-.708-2.75-2.75-.708.708zm2.75%202.75l2.75%202.75.708-.708-2.75-2.75-.708.708z%22%20fill%3D%22%231264A3%22%2F%3E%3C%2Fsvg%3E);
  content: "";
  width: 19px;
  height: 13px;
  display: inline-block;
  margin-left: 0.5em;
}

/* ecwid storefront */
.ecwid div {
  @apply text-chocolate font-sans;
}

.ecwid p {
  @apply text-chocolate font-sans;
} 

.ecwid a {
  @apply text-chocolate font-sans;
}

.ecwid span {
  @apply text-chocolate font-sans;
}

